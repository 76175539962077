import { RementoPage } from '@/modules/routing';

import { Container } from './WelcomePage.styles';

function Welcome() {
  return (
    <Container>
      <iframe
        src="https://player.vimeo.com/video/894261383?h=cf6b1a1b64&badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479"
        width="100%"
        height="100%"
        allow="autoplay; fullscreen; picture-in-picture"
      />
    </Container>
  );
}

export function WelcomePage() {
  return (
    <RementoPage type="empty">
      <Welcome />
    </RementoPage>
  );
}
